<template>
    <modal ref="modalCalificacion" titulo="Calificación promedio del curso" noCancelar noAceptar cerrar>
        <div class="row mx-0">
            <div class="col border-right text-center my-3">
                <p class="text-gris f-18 text-gris">
                    Promedio de <br /> calificación
                </p>
                <p class="text-general f-30 f-600 my-3">
                    {{ calificacion }}
                </p>
            </div>
            <div class="col border-left text-center my-3">
                <p class="text-gris f-18 text-gris">
                    Total de personas que calificaron
                </p>
                <p class="text-general f-30 f-600 my-3">
                    {{ calificaciones }}
                </p>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        calificacion:{
            type: Number,
            default: 0.0
        },
        calificaciones:{
            type: Number,
            default: 0.0
        },
    },
    methods: {
        toggle(){
            this.$refs.modalCalificacion.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>